<template>
  <div>
    <div class="row" style="margin: 5px">
      <div class="col-md-10 col-9">
        <h2 v-if="transferOut" class="font-weight-bold">
          {{ $t("tranferOut") }}
        </h2>
        <h2 v-if="transferIn" class="font-weight-bold">
          {{ $t("stockInDocument") }}
        </h2>
      </div>
      <div class="col-md-2 col-4 text-right align-text-center">
        <a id="transferOutDetailBackButton" @click="$router.go(-1)">
          <CButton>
            <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
          </CButton>
        </a>
      </div>
    </div>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm" style="border-radius: 0.1rem">
        <CCardBody>
          <CAlert id="transferOutDetailSuccessAlert" color="success" v-if="editSuccess === true">
            {{ $t("dialogSaveTransferDocumentSuccess") }}
          </CAlert>
          <CAlert id="transferOutDetailErrorAlert" color="danger" v-if="errorAlert">{{ $t('onhandQtyBalanceLessthan0') }}</CAlert>
          <CAlert id="transferOutDetailSaveSuccessAlert" color="success" v-if="isSaveSuccess != ''">
            {{ isSaveSuccess }}
          </CAlert>
          <div class="row">
            <div class="col-md-12 col-12">
              <h4 class="font-weight-bold">
                {{ $t("information") }}
              </h4>
            </div>
          </div>
          <br />
          <CRow>
            <CCol lg="6" col="12">
              <CRow class="form-group row">
                <label class="col-3 col-form-label h5">
                    {{ $t("sourceBranch") }} :
                </label>
                <div id="transferOutDetailSourceBranchSelectContainer" class="col-9" v-if="transferOut">
                  <select id="transferOutDetailSourceBranchSelect" class="custom-select" v-model="shopObjectId" disabled>
                    <option
                      v-for="shop in shops"
                      :key="shop.objectId"
                      :value="shop.objectId"
                    >
                      {{ shop.shopName }} - {{ shop.branchName }}
                    </option>
                  </select>
                </div>
                <div id="transferOutDetailDestinationBranchContainer" class="col-9" v-else>
                  <select
                    id="transferOutDetailDestinationBranchSelect"
                    disabled
                    class="custom-select"
                    v-model="destinationShop"
                  >
                    <option
                      v-for="shop in shops"
                      :key="shop.objectId"
                      :value="shop.objectId"
                    >
                      {{ shop.shopName }} - {{ shop.branchName }}
                    </option>
                  </select>
                </div>
              </CRow>
            </CCol>
            <CCol lg="6" col="12">
              <CRow class="form-group row">
                <label class="col-3 col-form-label h5">
                  {{ $t("destinationShopBranch") }} :
                </label>
                <div class="col-9" v-if="transferOut">
                  <select
                    id="transferOutDetailDestinationBranch"
                    disabled
                    class="custom-select"
                    v-model="destinationShop"
                  >
                    <option
                      v-for="shop in shops"
                      :key="shop.objectId"
                      :value="shop.objectId"
                    >
                      {{ shop.shopName }} - {{ shop.branchName }}
                    </option>
                  </select>
                  </div>
                  <div class="col-9" v-else>
                  <select id="transferOutDetailSourceBranch" class="custom-select" v-model="shopObjectId" disabled>
                    <option
                      v-for="shop in shops"
                      :key="shop.objectId"
                      :value="shop.objectId"
                    >
                      {{ shop.shopName }} - {{ shop.branchName }}
                    </option>
                  </select>
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="6" col="12">
              <CRow class="form-group row">
                <label class="col-3 col-form-label h5">
                  {{ $t("createdAt") }} :
                </label>
                <div class="col-9">
                  <VueDatePicker
                    id="transferOutDetailCreatedAtPicker"
                    disabled
                    color="#29B46B"
                    :locale="getDateLocale()"
                    :visible-years-number="90"
                    v-model="created"
                    format="DD-MM-YYYY"
                    class="form-control text-dark"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input :value="inputValue" v-on="inputEvents" />
                    </template>
                  </VueDatePicker>
                </div>
              </CRow>
            </CCol>
            <CCol lg="6" col="12">
              <CRow class="form-group row">
                <label class="col-3 col-form-label h5">
                  {{ $t("dueDate") }} :
                </label>
                <div class="col-9">
                  <VueDatePicker
                    id="transferOutDetailDueDatePicker"
                    color="#29B46B"
                    :locale="getDateLocale()"
                    :visible-years-number="90"
                    v-model="dueDate"
                    format="DD-MM-YYYY"
                    class="form-control text-dark"
                    :disabled="!(transferOut && status == 'P')"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input :value="inputValue" v-on="inputEvents" />
                    </template>
                  </VueDatePicker>
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol col="6" class="form-group">
              <div class="font-weight-bold h5">
              <span >{{ $t("documentNo") }} :</span>
              <span id="transferOutDetailDocumentNo" style="margin-left: 40px;">{{ this.stockNo }}</span>
              </div>
            </CCol>
            <CCol col="6" class="form-group">
              <div class="font-weight-bold h5">
                <span>{{ $t("adjustDocType") }} :</span>
                <span id="transferOutDetailDocType" style="margin-left: 22px;">{{ this.DocType }}</span>
              </div>
            </CCol>
          </CRow>          
        </CCardBody>
      </CCard>
      <CCard class="shadow-sm" style="border-radius: 0.1rem">
        <CCardBody>
          <div class="row">
            <div v-if="transferOut" class="col-md-10 col-8">
              <h4  class="font-weight-bold">{{ $t("transferoutList") }} </h4>
              <p>
                {{ $t("transferoutList") }}
              </p>
            </div>
            <!-- <div v-show="!isDisabled && !this.transferIn" class="col-2">
            <CButton
            color="outline-success"
            class="col-12"
            @click="saveDocument(transferObjectId)"
            >
            {{ $t('saveEdit') }}
            </CButton>
            </div> -->
            <div v-if="transferIn" class="col-md-10 col-8">
              <h4 class="font-weight-bold">{{ $t("transferoutList") }} </h4>
              <p>
                {{ $t("transferinList") }}
              </p>
            </div>
          </div>
          <CRow v-if="items && items.length != 0">
            <CCol sm="12" lg="12">
              <br v-if="items && items.length != 0" />
              <div style="overflow-x: auto">
                <p v-if="duplicateItem != ''" class="text-danger">
                  {{ duplicateItem }}
                </p>
                <table
                  class="table table-responsive  table-bordered text-dark"
                  v-if="items.length > 0"
                  style="min-width: max-content"
                >
                  <thead>
                    <tr>
                      <th style="width: 2%" class="text-center text-dark">
                        {{ $t("number") }}
                      </th>
                      <th class="text-dark">{{ $t("pluCode") }}</th>
                      <th class="text-dark">{{ $t("productName") }}</th>
                      <th class="text-dark" style="width: 8%">{{ $t("unit") }}</th>
                      <th class="text-dark" v-if="transferOut" v-show="status == 'P'">{{ $t("onhandNow") }}</th>
                      <th class="text-right text-dark" style="width: 9%">{{ $t("transferQty") }}</th>
                      <th v-if="transferOut" v-show="status == 'A'" class="text-right text-dark" style="width: 9%">{{ $t("transferReceived") }}</th>
                      <th v-if="transferIn" class="text-right text-dark" style="width: 9%">{{ $t("quantityReceived") }}</th>
                      <th v-if="transferIn" v-show="status == 'P'" style="width: 9%" class="text-right text-dark">{{ $t("inventoryBalance") }}</th>
                      <th class="text-left text-dark" style="width: 25%">{{ $t("reason") }}</th>
                      <!-- <th></th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index" v-if="!item.deleted_at">
                      <td class="text-center" :value="(item.orderIndex = index)" :id="'transferOutDetailNumber'-{index}">
                        <div style="margin-top: 6px">
                          {{ index + 1 }}
                        </div>
                      </td>
                      <td :id="'transferOutDetailPLUCode'-{ index }">
                        <div style="margin-top: 6px">
                          {{ item.productPLU.PLUCode }}
                        </div>
                      </td>
                      <td :id="'transferOutDetailProductName'-{ index }">
                        <div style="margin-top: 6px">
                          {{ item.productPLU.name }}
                        </div>
                      </td>
                      <td :id="'transferOutDetailUnit'-{ index }">
                        <div style="margin-top: 6px">1 / {{ item.productPLU.unit.name }}</div>
                      </td>
                      
                      <td :id="'transferOutDetailOnHandQty'-{ index }" v-if="transferOut" v-show="status == 'P'" >
                        <input
                          style="width: 100%; height: 100%"
                          type="number"
                          min="0"
                          class="form-control form-control-sm text-right text-dark"
                          :value="calculateOnhandTransferOut(item)"
                          disabled
                        />
                        <p
                          class="text-danger"
                          v-if="
                            Number(item.onhandTransferIn < 0) ||
                            Number.isNaN(parseInt(item.onhandTransferIn))
                          "
                        >
                          {{ $t("dialogfound") }}
                        </p>
                      </td>
                      <td :id="'transferOutDetailTransferQty'-{ index }" v-if="transferOut">
                        <input
                          style="width: 100%; height: 100%"
                          type="number"
                          min="0"
                          class="form-control form-control-sm text-right text-dark"
                          v-model.number="item.quantity"
                          :disabled="isDisabled"

                        />
                        <p
                          class="text-danger"
                          v-if="
                            Number(item.quantity < 0) ||
                            Number.isNaN(parseInt(item.quantity))
                          "
                        >
                          {{ $t("dialogfound") }}
                        </p>
                      </td>
                      <td :id="'transferOutDetailTransferReceivedQty'-{ index }" v-if="transferOut" v-show="status == 'A'" >
                        <input
                          style="width: 100%; height: 100%"
                          type="number"
                          min="0"
                          class="form-control form-control-sm text-right text-dark"
                          v-model.number="item.quantityReceived"
                          :disabled="isDisabled"

                        />
                      </td>
                      <td :id="'transferOutDetailQtyReceived'-{ index }" v-if="transferIn">
                        <input
                          style="width: 100%; height: 100%"
                          type="number"
                          min="0"
                          class="form-control form-control-sm text-right text-dark"
                          v-model.number="item.quantity"
                          disabled
                        />
                        <p
                          class="text-danger"
                          v-if="
                            Number(item.quantity < 0) ||
                            Number.isNaN(parseInt(item.quantity))
                          "
                        >
                          {{ $t("dialogfound") }}
                        </p>
                      </td>
                      <td :id="'transferOutDetailInventoryBalance'-{ index }" v-if="transferIn">
                        <input
                          style="width: 100%; height: 100%"
                          type="number"
                          min="0"
                          class="form-control form-control-sm text-right text-dark"
                          v-model.number="item.quantityReceived"
                          :disabled="isDisabled"
                        />
                        <p
                          class="text-danger"
                          v-if="
                            Number(item.quantityReceived < 0) ||
                            Number.isNaN(parseInt(item.quantityReceived)) ||
                            item.quantityReceived > item.quantity
                          "
                        >
                          {{ $t("dialogfound") }}
                        </p>
                      </td>
                      <p style="display: none">
                        {{
                          (calculateOnhand =
                            Number(item.quantityReceived) + item.onhandTransferIn)
                        }}
                      </p>
                      <td v-if="transferIn"  v-show="status == 'P'">
                        <input
                          style="width: 100%; height: 100%"
                          type="number"
                          :value="calculateOnhand"
                          class="form-control form-control-sm text-right text-dark"
                          disabled
                        />
                        <p
                          class="text-danger"
                          v-if="
                            Number(item.quantityReceived < 0) ||
                            Number.isNaN(parseInt(item.quantityReceived)) ||
                            item.quantityReceived > item.quantity
                          "
                        >
                          {{ $t("dialogfound") }}
                        </p>
                      </td>
                      <td :id="'transferOutDetailReason'-{ index }">
                        <div style="position: relative; width: 100%; height: 100%;">
                          <input
                            ref="input"
                            style="width: 100%; box-sizing: border-box;"
                            :class="{'form-control form-control-sm text-left': true, 'text-dark': !isDiffQuantity(item), 'text-danger': isDiffQuantity(item)}"
                            :value="displayValue(item)"
                            @input="updateNote($event, item)"
                            :disabled="isDisabled"
                          >
                        </div>
                      </td>

                      <!-- <td class="text-center" style="width: 2%">
                        <CButton
                          color="danger"
                          size="sm"
                          @click="removeProduct(item.index)"
                          :disabled="isDisabled"

                        >
                          <CIcon name="cil-trash"></CIcon>
                        </CButton>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <div v-if="validateItem" class="text-danger">{{ validateItem }}</div>
          <br v-if="items.length > 0" />
          <CRow v-if="items.length > 0">
            <CCol sm="12" lg="12">
              <CTextarea
                id="transferOutDetailNote"
                v-model="note"
                :label="$t('reason')"
                :placeholder="$t('reason')"
                rows="3"
                :disabled="isDisabled"
              />
            </CCol>
          </CRow>
          <br v-if="items.length > 0" />
        </CCardBody>
      </CCard>
    </CCol>
    <CCol >
      <div class="col-12 d-flex justify-content-end" style="margin: 0">
        <!-- <div class="col-lg-1"> -->
        <CButton
          id="transferOutDetailDeleteButton"
          class="btn btn-danger col-3"
          style=" font-weight: bold; margin-right: 15px"
          @click="deleteModal = true"
          v-show="!isDisabled && transferOut"
        >
          {{ $t("deleteDoc") }}
        </CButton>
        <!-- </div>
          <div class="col-lg-1"> -->
        <CButton
          id="transferOutDetailSaveButton"
          class="btn btn-success col-3"
          @click="saveDocument(transferObjectId)"
          v-if="items.length != 0"
          :disabled="hasInvalidQuantity"
          v-show="!isDisabled && transferOut"
        >
          {{ $t("saveEdit") }}
        </CButton>
        <CButton
          id="transferOutDetailApproveButton"
          class="btn btn-success col-3"
          @click="validateDoc"
          v-if="items.length != 0"
          :disabled="disableTransferButton"
          v-show="!isDisabled && transferIn"
        >
          {{ $t("approve") }}
        </CButton>
        <CButton id="transferOutDetailDisabledButton" class="btn btn-success col-3" v-else disabled>
          {{ $t("transfer") }}
        </CButton>
        <!-- </div> -->
      </div>
      <br />
    </CCol>
    <CRow>
      <CModal id="transferOutDetailDeleteModal" :show.sync="deleteModal" color="danger" :title="$t('confirmDeleteDoc')" centered :footer="deleteFooter">
        <br />
        <h4 class="text-center">
          {{ $t('confirmDeleteTransferModal') }}
        </h4>
        <br />

        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="4">
              <CButton id="transferOutDetailDeleteModalConfirmButton" block color="danger" v-if="loadingButton === false" 
                v-on:click="deleteTransferDocument(transferObjectId)">
                {{ $t('confirm') }}
              </CButton>
              <CButton id="transferOutDetailDeleteModalLoadingButton" block color="danger" v-else-if="loadingButton === true" disabled>
                <CSpinner color="white" size="sm" /> {{ $t('confirm') }}
              </CButton>
            </CCol>
            <CCol col="2"></CCol>
            <CCol col="4">
              <CButton id="transferOutDetailDeleteModalCancelButton" block color="light" @click="deleteModal = false">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>
    <CRow>
      <CModal
        id="transferOutDetailInvalidModal"
        :show.sync="invalidTransferModal"
        color="warning"
        :title="$t('transferDocumentCreationFailed')"
        centered
        :footer="footer"
      >
        <br />
        <h4 class="text-center">
          {{this.message}}
        </h4>
        <br />
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="6">
              <CButton id="transferOutDetailInvalidModalSubmitButton" block color="light" @click="$router.push({name: 'TransferOut',})">
                {{ $t("submit") }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>
    <CModal
      id="transferOutDetailConfirmSaveModal"
      :show.sync="confirmSave"
      centered
      :title="$t('confirmSaveDoc')"
      color="success"
      header="false"
    >
    <br>
      <CRow class="justify-content-center col-md-12" v-if="loadingButton === false">
        <h4 class="text-center">
          {{ $t("ApproveDocDes") }}
        </h4>
      </CRow>
      <div style="text-align: center" v-if="loadingButton === true">
        <CSpinner color="success" size="lg" class="text-success" centered />
        <br />
        <br />
        <h4 class="text-success">{{ $t("savingDoc") }}</h4>
        .
      </div>
      <template #footer>
        <CRow class="justify-content-around col-md-12">
          <CCol col="4" v-if="loadingButton === false">
            <CButton id="transferOutDetailApproveSaveButton" @click="saveDocumentStatusA(transferObjectId)" color="success" block>
              <b>{{ $t("ApproveTransfer") }}</b>
            </CButton>
          </CCol>
          <CCol col="4" v-show="transferOut" v-if="loadingButton === false">
            <CButton id="transferOutDetailCancelApproveButton" @click="confirmSave = false" color="light" block>
              <b> {{ $t("cancel") }}</b>
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>
    <!-- <CModal
      :show.sync="editSuccess"
      centered
      :title="$t('confirmSaveDoc')"
      color="success"
    >
    <br>
    <h4 class="text-center"> {{ $t('docSaveEditComplete') }}</h4>
    <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="6">
              <CButton block color="light" @click="editSuccessModal()">
                {{ $t("submit") }}
              </CButton>
            </CCol>
          </CRow>
        </template>
    </CModal> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import util from "@/util/util";
import Multiselect from "vue-multiselect";
import axios from "@/services/local";
import local from "@/services/local";
import { CButton } from "@coreui/vue";

export default {
  components: {
    // autocomplete,
    Multiselect,
  },
  data() {
    return {
      isSuccess: false,
      alert: false,
      data: [],
      quantity: 0,
      datetime: new Date(),
      shop: [],
      inventory: [],
      branch: [],
      isVatIncluded: true,
      billNo: "",
      note: "",
      discount: 0,
      validateShop: "",
      validateItem: "",
      loadingButton: false,
      duplicateItem: "",
      destinationShop: this.$store.getters.shopObjectId,
      destinationShopDetail: "",
      validatedata: "",
      isSaveSuccess: "",
      confirmSave: false,
      footer: "",
      items: [],
      transferOut: false,
      transferIn: false,
      invalidTransferModal: false,
      message: '',
      DocType:'',
      stockNo:'',
      status:'',
      deleteModal: false,
      deleteFooter: '',
      docObjectId: '',
      docId: '',
      editSuccess: false,
      created: new Date(),
      dueDate: new Date(),
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date", "permissions"]),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    shopdetail() {
      return this.shops.find((i) => i.objectId === this.shopObjectId);
    },
    shopOptions() {
      let detail = [];
      this.shops.forEach((shop) => {
        detail.push({
          value: shop,
          label: shop.shopName + " - " + shop.branchName,
        });
      });
      return detail;
    },
    transferObjectId() {
      return this.$route.params.transferObjectId;
    },
    isDisabled() {
      return this.status === 'A' || this.status === 'C';
    },
    disableTransferButton() {
      return this.items.some(item => item.quantityReceived > item.quantity);
    },
    displayValue() {
      return item => {
        if (this.status === 'A' && item.quantityReceived < item.quantity) {
          return item.note + ', ' + this.$t('diffQuantity');
        }
        return item.note;
      };
    },
    hasInvalidQuantity() {
      return this.items.some(item => item.quantity > (item.onhandTransferIn + item.firstQuantity));
    },
    errorAlert() {
      return this.hasInvalidQuantity
    }
  },
  created() {
    this.getTransferDetail()
    this.searchProductPLU()
  },
  mounted() {
    this.$store.dispatch("getShop");
    this.$store.dispatch("getUser");
  },
  watch: {
    '$i18n.locale': 'updateDocType',
  },
  methods: {
    ...util,
    calculateOnhandTransferOut(item) {
      if (item.quantity === item.firstQuantity) {
        return item.onhandTransferIn;
      } else {
        return Number((item.onhandTransferIn + item.firstQuantity) - item.quantity);
      }
    },
    setEditSuccess() {
      this.editSuccess = true
      window.scrollTo({ top: 0 })
      setTimeout(() => {
        this.editSuccess = false
      }, 3000)
    },
    isDiffQuantity(item) {
      return this.status === 'A' && item.quantityReceived < item.quantity;
    },
    updateNote(event, item) {
      const inputValue = event.target.value;
      const diffText = ', ' + this.$t('diffQuantity');
      if (this.status === 'A' && item.quantityReceived < item.quantity) {
        if (inputValue.endsWith(diffText)) {
          item.note = inputValue.slice(0, -diffText.length);
        } else {
          item.note = inputValue;
        }
      } else {
        item.note = inputValue;
      }
    },
    updateDocType() {
      if(this.transferIn) {
        this.DocType = this.$t('transferInTxt');
      } else if(this.transferOut) {
        this.DocType = this.$t('transferOutTxt');
      }
    },
    getDateLocale() {
      if (this.$i18n.locale == "en") {
        return { lang: "en" };
      } else {
        return { lang: "de" };
      }
    },
    customLabel(option) {
      return `${option.PLUCode} - ${option.name}`;
    },
    removeProduct(index) {
    let items = this.items.map(item => {
      if (item.index === index) {
        return { ...item, deleted_at: new Date().toISOString() };
      }
      return item;
    });
    this.items = items;
    },
    editSuccessModal(){
      this.editSuccess = false
      this.getTransferDetail()
    },
    validateDoc() {
      if (this.shopObjectId == this.destinationShop) {
        this.validatedata = this.$i18n.t("selectdestinationPlease");
      } else if (this.destinationShop == "") {
        this.validatedata = this.$i18n.t("selectdestinationPlease");
      } else {
        if (this.items.length == 0) {
          this.validatedata = this.$i18n.t("validateProduct");
        } else {
          this.validatedata = "";
          this.confirmSave = true;
        }
      }
    },
    saveDocument() {
      this.loadingButton = false
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      this.destinationShopDetail = this.shops.find(
        (i) => i.objectId === this.destinationShop
      );
      const uid = `${localStorage.getItem("shopsUid")}`;
      let item = this.items;
      let dataitems = [];
      this.loadingButton = true;
      const params = {
        objectId: this.transferObjectId
      }
      const shopObjectId = this.shopObjectId
      const headers = { shopObjectId: shopObjectId }
      for (let i = 0; i < item.length; i++) {
        if(this.transferIn){
            dataitems.push({
            quantity: parseFloat(item[i].quantity),
            orderIndex: i,
            productPLUId: item[i].productPLUId,
            productPLU: {
              id: item[i].productPLU.id,
              objectId: item[i].productPLU.objectId,
              PLUCode: item[i].productPLU.PLUCode,
              name: item[i].productPLU.name,
              unit: item[i].productPLU.unit,
              SKURatio: item[i].productPLUSKURatio,
            },
            productSKUObjectId: item[i].productSKU.objectId,
            productSKU: item[i].productSKU || {},
            note: item[i].note,
            quantityReceived: parseFloat(item[i].quantityReceived),
            vatType: item[i].vatType,
            previousOnhand: parseFloat(item[i].previousOnhand)
          });
        } else if (this.transferOut){
          dataitems.push({
            quantity: parseFloat(item[i].quantity),
            orderIndex: i,
            productPLUId: item[i].productPLUId,
            productPLU: {
              id: item[i].productPLU.id,
              objectId: item[i].productPLU.objectId,
              PLUCode: item[i].productPLU.PLUCode,
              name: item[i].productPLU.name,
              unit: item[i].productPLU.unit,
              SKURatio: item[i].productPLU.SKURatio,
            },
            productSKUObjectId: item[i].productSKU.objectId,
            productSKU: item[i].productSKU || {},
            note: item[i].note,
            vatType: item[i].vatType,
            previousOnhand: parseFloat(item[i].previousOnhand)
        })
      }
      }
      let data = [];
      if(this.transferIn){
        data = {
          datetime: this.datetime,
          note: this.note,
          shopId: this.shop.id,
          shop: {
            id: this.shop.id,
            objectId: this.shop.objectId,
            shopName: this.shop.shopName,
            branchName: this.shop.branchName,
          },
          source: {
            id: this.destinationShopDetail.id,
            objectId: this.destinationShopDetail.objectId,
            shopName: this.destinationShopDetail.shopName,
            branchName: this.destinationShopDetail.branchName,
          },
          items: dataitems,
          id : this.docId,
          objectId: this.docObjectId,
          updated_at: new Date().toISOString() 
        }
      }else if(this.transferOut){
        data = {
          datetime: this.datetime,
          note: this.note,
          shopId: this.shop.id,
          shop: {
            id: this.shop.id,
            objectId: this.shop.objectId,
            shopName: this.shop.shopName,
            branchName: this.shop.branchName,
          },
          site: {
            id: this.destinationShopDetail.id,
            objectId: this.destinationShopDetail.objectId,
            shopName: this.destinationShopDetail.shopName,
            branchName: this.destinationShopDetail.branchName,
          },
          items: dataitems,
          id : this.docId,
          objectId: this.docObjectId,
          status: 'P',
          updated_at: new Date().toISOString(),
          dueDate: this.dueDate
        }
      }
      
      local({
        method: "patch",
        url: "/api/v1.0/" + uid + '/stockdocument/transfer/out',
        data: data,
        params: params,
        headers: headers
      })
        .then((res) => {
          this.loadingButton = false;
          this.confirmSave = false;
          if(res.data.error.code != 0){
            this.invalidTransferModal = true
            this.message = this.$t('saveDocInvalid')
          }else{
            this.setEditSuccess()
          }
          let transposeObjectId = res.data.data.objectId;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveDocumentStatusA() {
    this.loadingButton = false;
    this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
    this.destinationShopDetail = this.shops.find(
      (i) => i.objectId === this.destinationShop
    );
    const uid = `${localStorage.getItem("shopsUid")}`;
    let item = this.items;
    let dataitems = [];
    this.loadingButton = true;
    let url = '';
    let diffQuantity = false
    if (this.transferIn) {
      url = "/api/v1.0/" + uid + '/stockdocument/transfer/in';
    } else if (this.transferOut) {
      url = "/api/v1.0/" + uid + '/stockdocument/transfer/out';
    }
    const shopObjectId = this.$store.getters.shopObjectId 
    const headers = { shopObjectId: shopObjectId }
    const params = {
      objectId: this.transferObjectId,
    };
    for (let i = 0; i < item.length; i++) {
      let newItem = {
        id: item[i].id,
        quantity: parseFloat(item[i].quantity),
        orderIndex: i,
        productPLUId: item[i].productPLUId,
        productPLU: {
          id: item[i].productPLU.id,
          objectId: item[i].productPLU.objectId,
          PLUCode: item[i].productPLU.PLUCode,
          name: item[i].productPLU.name,
          unit: item[i].productPLU.unit,
          SKURatio: item[i].productPLU.SKURatio,
        },
        productSKUObjectId: item[i].productSKU.objectId,
        productSKU: item[i].productSKU || {},
        note: item[i].note,
        quantityReceived: parseFloat(item[i].quantityReceived),
        vatType: item[i].productSKU.vatType,
        previousOnhand: parseFloat(item[i].previousOnhand),
      };

      if (item[i].quantity !== item[i].quantityReceived) {
        newItem = { ...newItem, diffQuantity: true };
      }
      
      dataitems.push(newItem);
    }
    let hasDiffQuantity = dataitems.some(item => item.diffQuantity === true);
    let data = {};
    if (this.transferIn) {
      data = {
        datetime: this.datetime,
        note: this.note,
        shopId: this.shop.id,
        shop: {
          id: this.shop.id,
          objectId: this.shop.objectId,
          shopName: this.shop.shopName,
          branchName: this.shop.branchName,
        },
        source: {
          id: this.destinationShopDetail.id,
          objectId: this.destinationShopDetail.objectId,
          shopName: this.destinationShopDetail.shopName,
          branchName: this.destinationShopDetail.branchName,
        },
        items: dataitems,
        id: this.docId,
        objectId: this.docObjectId,
        status: 'A',
        uid: uid,
        updated_at: new Date().toISOString() 
      };
    } else if (this.transferOut) {
      data = {
        datetime: this.datetime,
        note: this.note,
        shopId: this.shop.id,
        shop: {
          id: this.shop.id,
          objectId: this.shop.objectId,
          shopName: this.shop.shopName,
          branchName: this.shop.branchName,
        },
        site: {
          id: this.destinationShopDetail.id,
          objectId: this.destinationShopDetail.objectId,
          shopName: this.destinationShopDetail.shopName,
          branchName: this.destinationShopDetail.branchName,
        },
        items: dataitems,
        id: this.docId,
        objectId: this.docObjectId,
        status: 'A',
        uid: uid,
        updated_at: new Date().toISOString(),
        dueDate: this.dueDate
      };
    }
    if (hasDiffQuantity) {
    data.diffQuantity = true;
    }

    axios({
      method: "patch",
      headers: headers,
      url: url,
      data: data,
      params: params,
    })
      .then((res) => {
        this.loadingButton = false;
        this.confirmSave = false;
        if (res.data.error.code === 4000) {
          this.invalidTransferModal = true;
          this.message = this.$t('saveDocInvalid');
        }else {
        if (this.transferIn) {
          this.$router.push({
            name: 'TransferIn'
          });
        } else if (this.transferOut) {
          this.$router.push({
            name: 'TransferOut'
          });
        }
      }
        let transposeObjectId = res.data.data.objectId;
      })
      .catch((error) => {
        console.log(error);
      });
  },
    deleteTransferDocument() {
      this.loadingButton = false
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      this.destinationShopDetail = this.shops.find(
        (i) => i.objectId === this.destinationShop
      );
      const uid = `${localStorage.getItem("shopsUid")}`;
      let item = this.items;
      let dataitems = [];
      this.loadingButton = true;
      const params = {
        objectId: this.transferObjectId
      }
      const shopObjectId = this.shopObjectId
      const headers = { shopObjectId: shopObjectId }
      for (let i = 0; i < item.length; i++) {
        if(this.transferIn){
            dataitems.push({
            quantity: item[i].quantity,
            orderIndex: i,
            productPLUId: item[i].productPLUId,
            productPLU: {
              id: item[i].productPLU.id,
              objectId: item[i].productPLU.objectId,
              PLUCode: item[i].productPLU.PLUCode,
              name: item[i].productPLU.name,
              unit: item[i].productPLU.unit,
              SKURatio: item[i].productPLU.SKURatio,
            },
            productSKUObjectId: item[i].productSKU.objectId,
            productSKU: item[i].productSKU || {},
            note: item[i].note,
            quantityReceived: item[i].quantityReceived,
            vatType: item[i].productSKU.vatType,
            previousOnhand: item[i].previousOnhand
          });
        } else if (this.transferOut){
          dataitems.push({
            quantity: item[i].quantity,
            orderIndex: i,
            productPLUId: item[i].productPLUId,
            productPLU: {
              id: item[i].productPLU.id,
              objectId: item[i].productPLU.objectId,
              PLUCode: item[i].productPLU.PLUCode,
              name: item[i].productPLU.name,
              unit: item[i].productPLU.unit,
              SKURatio: item[i].productPLU.SKURatio,
            },
            productSKUObjectId: item[i].productSKU.objectId,
            productSKU: item[i].productSKU || {},
            note: item[i].note,
            vatType: item[i].productSKU.vatType,
            previousOnhand: item[i].previousOnhand
        })
      }
      }
      let data = [];
      if(this.transferIn){
        data = {
          datetime: this.datetime,
          note: this.note,
          shopId: this.shop.id,
          shop: {
            id: this.shop.id,
            objectId: this.shop.objectId,
            shopName: this.shop.shopName,
            branchName: this.shop.branchName,
          },
          source: {
            id: this.destinationShopDetail.id,
            objectId: this.destinationShopDetail.objectId,
            shopName: this.destinationShopDetail.shopName,
            branchName: this.destinationShopDetail.branchName,
          },
          items: dataitems,
          id : this.docId,
          objectId: this.docObjectId,
          status: 'C'
        }
      }else if(this.transferOut){
        data = {
          datetime: this.datetime,
          note: this.note,
          shopId: this.shop.id,
          shop: {
            id: this.shop.id,
            objectId: this.shop.objectId,
            shopName: this.shop.shopName,
            branchName: this.shop.branchName,
          },
          site: {
            id: this.destinationShopDetail.id,
            objectId: this.destinationShopDetail.objectId,
            shopName: this.destinationShopDetail.shopName,
            branchName: this.destinationShopDetail.branchName,
          },
          items: dataitems,
          id : this.docId,
          objectId: this.docObjectId,
          status: 'C'
        }
      }
      
      local({
        method: "patch",
        url: "/api/v1.0/" + uid + '/stockdocument/transfer/out',
        data: data,
        params: params,
        headers: headers
      })
        .then((res) => {
          this.loadingButton = false;
          this.confirmSave = false;
          if(res.data.error.code === 4000){
            this.invalidTransferModal = true
            this.message = this.$t('saveDocInvalid')
          }else{
            this.$router.push({
              name: 'TransferOut'
            })
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTransferDetail(){
        this.loadingButton = false;
        const uid = `${localStorage.getItem("shopsUid")}`;
        const shopObjectId = this.shopObjectId;
        this.loading = true;
        const transferObjectId = this.$route.params.transferObjectId;
        const params = {
          shopObjectId : shopObjectId
        }
        const headers = {
        shopObjectId: this.$store.getters.shopObjectId,
      }
        axios({
        method: 'get',
        url: '/api/v1.0/' + uid + '/stockdocument/transfer/' + transferObjectId,
        params: params,
        headers: headers
      }).then((res) => {
        if (res.data.error.code === 4000){
            this.invalidTransferModal = true
            if(res.data.error.message = 'Stock document not found'){
              this.message = this.$t('TransferDocNotFound')
            }
          }else{
            this.data = res.data.data;  
            this.loadingButton = false;
            this.loading = false;
            if(this.data.stockDocType === 3){
              this.transferOut = true
              this.destinationShop = this.data.site.objectId
              this.destinationShopDetail = this.data.site
              this.items = this.data.items.map(item => {
                return {
                  ...item,  
                  firstQuantity: item.quantity
                };
              });
              this.DocType = this.$t('transferOutTxt')
              this.stockNo = this.data.stockNo
              this.status = this.data.status
              this.note = this.data.note
              this.docId = this.data.id
              this.docObjectId = this.data.objectId
              this.created = this.data.created_at
              this.dueDate = this.data.dueDate
            }else if(this.data.stockDocType === 4){
              this.transferIn = true
              this.destinationShop = this.data.source.objectId
              this.destinationShopDetail = this.data.source
              this.items = this.data.items
              this.DocType = this.$t('transferInTxt')
              this.stockNo = this.data.stockNo
              this.status = this.data.status
              this.note = this.data.note
              this.docId = this.data.id
              this.docObjectId = this.data.objectId
              this.created = this.data.created_at
              this.dueDate = this.data.dueDate
            }
            this.searchProductPLU()
          }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    searchProductPLU() {
      if (this.shopObjectId === "") {
        this.validateShop = this.$i18n.t("selectShopPlease");
      } else {
        this.validateShop = "";
      }
      const uid = `${localStorage.getItem("shopsUid")}`;
      let params = {};
      if (this.keywordEntered != "") {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          keyword: this.keywordEntered,
        };
      } else {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
        };
      }
      const headers = { shopObjectId: this.$store.getters.shopObjectId };
      axios({
        url: "/api/v1.0/" + uid + "/getproductpluwithstock",
        params: params,
        headers: headers,
        method: "GET",
      })
        .then((response) => {
          this.alert = false;
          this.alert = false;
          const productPLUWithStock = response.data.data
          productPLUWithStock.forEach((item) => {
            const matchedItem = this.items.find(
              (transferItem) => transferItem.productPLU.id === item.id
            )
            if (matchedItem) {
              this.$set(matchedItem, 'onhandTransferIn', item.ProductPLUStock.onhandQty)
            }
          })
        })
        .catch((e) => {
          this.alert = true;
          console.log(e);
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="../../../assets/styles/inventory.css" scoped></style>
<style>
.multiselect__tags {
  min-height: 35px !important;
  height: 35px !important;
  padding-top: 6px !important;
}
</style>
